import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../firebase';
import { LoadProduction } from '../store/MetaStore';
import { Button, Card, CardBody, CardText, Col, Row } from 'react-bootstrap';
import CreateAppUser from './CreateAppUser';
import { FaSquareFacebook, FaWhatsapp } from 'react-icons/fa6';
import { FaMapMarkedAlt } from "react-icons/fa";
import BackgroundUploader from './Common/BackgroundUploader';


export default class LoadAppUser extends Component {
  constructor(props) {
    super(props)
    this.state = LoadProduction.init
    this.state.modalAlert = false
    this.handleConsole = this.handleConsole.bind(this)
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/')
      ? decodedPathname.substring('/'.length)
      : decodedPathname;
  }

  async componentDidMount() {


    const cleanedPathname = this.cleanPathname()
    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, () => {
          const favicon = document.getElementById('favicon');
          if (favicon) {
            favicon.href = this.state.setFavicon;
          } else {
            const newFavicon = document.createElement('link');
            newFavicon.id = 'favicon';
            newFavicon.rel = 'icon';
            newFavicon.href = this.state.setFavicon;
            document.head.appendChild(newFavicon);
          }
        })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 1500);
    }
  }

  handleConsole() {
    this.setState({
      utaStep: true,
      frameOn: ''
    })
  }


  render() {
    const { btnTxtTitle } = this.state;

    return (
      <div style={this.state.choiseColorBackground}>
        <header className='App-header'>
          <div className={this.state.choiseStyleMain + ' ' + this.state.frameOn}>
            {this.state.utaStep === false ?
              <Button onClick={this.handleConsole} className={`${this.state.choiseBtnColor} z-100`}>
                {btnTxtTitle ? btnTxtTitle[6] : 'Loading...'}
              </Button > : this.state.modalWelcome === true ? <CreateAppUser config={this.state} /> : <Card>
                <CardBody>
                  <CardText>{this.state.txtModalWelcome}</CardText>
                </CardBody>
              </Card>}
          </div> <BackgroundUploader
            img1={this.state.isEnabledFull ? this.state.srcImgMapBackFull : this.state.defaultImages.full}
            img2={this.state.isEnabledSky ? this.state.srcImgMapBackSky : this.state.defaultImages.sky}
            img3={this.state.isEnabledCenter ? this.state.srcImgMapBackCenter : this.state.defaultImages.center}
            img4={this.state.isEnabledBase ? this.state.srcImgMapBackBase : this.state.defaultImages.base} />
          <footer className='p-absolute bottom w-100 justify-center  z-100'>
            <Row className='  z-100'>
              <Col>
                {this.state.isValidUrl ? (
                  <div style={{ bottom: '0', color: 'blue', fontSize: '24px' }}>
                    <FaSquareFacebook className='c-pointer  z-100' onClick={() => { window.location = this.state.pointFaceUrl }} />
                  </div>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {this.state.isValidMap ? (
                  <div style={{ bottom: '0', fontSize: '24px', }}>
                    <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="googleGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#4285F4" />
                          <stop offset="25%" stopColor="#EA4335" />
                          <stop offset="50%" stopColor="#FBBC05" />
                          <stop offset="75%" stopColor="#34A853" />
                          <stop offset="100%" stopColor="#EA4335" />
                        </linearGradient>
                      </defs>
                      <FaMapMarkedAlt fill="url(#googleGradient)" className='c-pointer z-100' onClick={() => { window.location = this.state.pointMapsUrl }} />
                    </svg>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {this.state.isValidWhats ? (
                  <div style={{ bottom: '0', fontSize: '24px', }}>
                    <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="whatsGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#2FBC05" />
                          <stop offset="50%" stopColor="#34A853" />
                          <stop offset="75%" stopColor="#34A853" />
                          <stop offset="100%" stopColor="#414335" />
                        </linearGradient>
                      </defs>
                      <FaWhatsapp fill="url(#whatsGradient)" className='c-pointer z-100' onClick={() => { window.location = this.state.pointSeedWhatsUrl+this.state.pointWhatsUrl }} />
                    </svg>
                  </div>)
                  : (<>
                  </>)}
              </Col>
            </Row>
          </footer>
        </header>

      </div>
    )
  }
}
