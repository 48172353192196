import { doc, getDoc, setDoc, updateDoc, collection, getDocs, deleteDoc, } from 'firebase/firestore';
import React, { Component } from 'react';
import { Card, Col, Form, Modal, Row, Button, Offcanvas, CardGroup } from 'react-bootstrap';
import db from '../firebase';
import { LoadTicketCreation } from '../store/MetaStore';
import Cique from './Cique/Cique';

export default class LoadMonitorFrame extends Component {
  constructor(props) {
    super(props);
    this.state = LoadTicketCreation.init;
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  cleanPathname() {
    const { pathname } = window.location;
    const decodedPathname = decodeURIComponent(pathname);
    return decodedPathname.startsWith('/monitor/')
      ? decodedPathname.substring('/monitor/'.length)
      : decodedPathname;
  }

  componentDidMount = async () => {
    const { pathname } = window.location;
    const cleanedPathname = this.cleanPathname()

    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, async () => {
          const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
          const filteredTickets = [];

          ticketsSnapshot.forEach((doc) => {
            const ticketData = doc.data();
            if (ticketData.siteName === this.state.siteName) {
              filteredTickets.push({ id: doc.id, ...ticketData });
            }
          });
          this.setState({ filteredTickets });

        }, () => { })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 3500);
    }
  }

  componentWillUnmount = () => {
    this.clearUpdateInterval();
  }

  getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pendiente a pago':
        return 'yellow';
      case 'cancelado':
        return 'red';
      case 'error':
        return 'red';
      case 'pagado':
        return 'green';
      case 'completado':
        return 'blue';
      case 'preparando':
        return 'orange';
      default:
        return 'black';
    }
  }

  startInterval = () => {

    this.intervalId = setInterval(async () => {

      const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
      const filteredTickets = [];
      ticketsSnapshot.forEach((doc) => {
        const ticketData = doc.data();
        if (ticketData.siteName === this.state.siteName) {
          filteredTickets.push({ id: doc.id, ...ticketData });
        }
      });
      this.setState({ filteredTickets });

    }, 2500);
  };

  clearUpdateInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  handleModalStatus = (ticket) => {
    this.clearUpdateInterval();
    this.setState({
      modalStatusTicket: true,
      selectedTicket: ticket,
      pointStatusTicket: ticket.status,
    });
  }

  handleCloseModal = () => {
    this.setState({
      modalStatusTicket: false,
      selectedTicket: null,
      pointStatusTicket: '',
      frameConfirmClose: false,
    }, () => {
      if (!this.state.modalStatusTicket) {
        this.startInterval();
      }
    });
  }

  async handleChangeStatus(event) {
    const newStatus = event.target.value;
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();
    this.setState(prevState => {
      const updatedLog = [
        ...prevState.pointItemLog,
        {
          ticketId: ticketId,
          status: newStatus,
          date: currentDateTime
        }
      ];
      return {
        pointMonitor: {
          ...prevState.pointMonitor,
          ticketsNow: {
            ...prevState.ticketNow,
            state: newStatus
          },
        },
        pointStatusTicket: newStatus,
        pointItemLog: updatedLog,
      };
    });

    if (event.target.value === "cerrar") {
      this.setState({
        secureState: true,
        frameConfirmClose: true,
      });
    }
  }

  handleCloseOffcanvas = () => {
    this.setState({
      frameConfirmClose: false,
      modalStatusTicket: false,
      secureState: false,
    });
  }
  handleConfirmCloseTicket = async () => {
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();
    const ticketParent = this.state.filteredTickets.find(obj =>
      obj.pointMonitor.ticketsNow.some(ticket => ticket.idTicket === ticketId)
    );

    if (ticketParent) {
      const ticketToClose = ticketParent.pointMonitor.ticketsNow.find(ticket => ticket.idTicket === ticketId);

      if (ticketToClose) {
        const updatedTicketsNow = ticketParent.pointMonitor.ticketsNow.filter(ticket => ticket.idTicket !== ticketId);
        const updatedLog = [
          ...this.state.pointItemLog,
          {
            ticketId: ticketId,
            status: 'cerrar',
            date: currentDateTime
          }
        ];
        this.setState(prevState => ({
          filteredTickets: prevState.filteredTickets.map(obj =>
            obj === ticketParent
              ? {
                ...obj,
                pointMonitor: {
                  ...obj.pointMonitor,
                  ticketsNow: updatedTicketsNow
                }
              }
              : obj
          ),
          pointItemLog: updatedLog,
          frameConfirmClose: false,
          modalStatusTicket: false,
          secureState: false,
        }), async () => {
          try {
            await deleteDoc(doc(db, '$:__site-tickets', ticketId));

            await setDoc(doc(db, '$:__site-close', ticketId), {
              ...ticketToClose,
              status: 'cerrar',
              closedAt: currentDateTime,
              createdDate: ticketParent.createdDate,
              siteName: ticketParent.siteName
            });

            if (!this.state.modalStatusTicket) {
              this.startInterval();
            }

          } catch (error) {
            console.error("Error al mover el ticket:", error);
          }
        });
      }
    }
  }


  handleSaveStatus = async () => {
    const { selectedTicket, pointStatusTicket } = this.state;
    this.setState({ modalStatusTicket: false, selectedTicket: null, pointStatusTicket: '' },
      async () => {
        await updateDoc(doc(db, '$:__site-tickets', selectedTicket.idTicket), {
          pointMonitor: {
            ticketsNow: [{
              data: selectedTicket.data,
              nameId: selectedTicket.nameId,
              idTicket: selectedTicket.idTicket,
              mptrue: selectedTicket.mptrue,
              totalCost: selectedTicket.totalCost,
              status: pointStatusTicket
            }]
          }
        })
      })
    this.startInterval()

  };

  cique(c) {
    this.state.txtUserPass === c ? this.setState({ roleCreate: true }) : <></>
  }

  render() {

    const { filteredTickets } = this.state;
    if (!this.state.pageExist) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        {this.state.roleCreate === false ?
          <div className='App-header'>
            <Card>
              <Cique cique={(e) => { this.cique(e) }} />

            </Card></div> : <></>}
        {this.state.roleCreate === true ?
          <div className='App-header'>
            <div className='container'>
              <Row>
                {filteredTickets && filteredTickets.length > 0 ? (
                  filteredTickets.map((ticketObj, k) => {
                    const { ticketsNow } = ticketObj.pointMonitor;

                    return ticketsNow.map((ticket, index) => (
                      <Col key={`${k}-${index}`} md={4}>
                        <Card className="mb-3" onClick={() => this.handleModalStatus(ticket)}>
                          <Card.Body>
                            <Card.Title>{ticket.nameId}</Card.Title>
                            <CardGroup className="ticket-card">
                              {Object.entries(ticket.data).map(([key, value]) => (
                                Array.isArray(value) ? (
                                  <table key={key} style={{ fontSize: '0.8em', width: '100%', marginBottom: '1em' }}>
                                    <thead>
                                      <tr>
                                        <th>Para</th>
                                        <th>Cantidad</th>
                                        <th>Precio</th>
                                        <th>Detalles</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {value.map((item, index) => (
                                        item.keyItems.map((subItem, subIndex) => (
                                          <tr key={`${key}-${index}-${subIndex}`}>
                                            <td>{item.name || 'N/A'}</td>
                                            <td>{subItem.clicks || 'N/A'}</td>
                                            <td>{subItem.priceItem || 'N/A'}</td>
                                            <td>{subItem.keyItem || 'N/A'}</td>
                                          </tr>
                                        ))
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <Card.Text key={key} style={{ fontSize: '0.8em' }}>
                                    <strong>{key}:</strong> {value.name || 'N/A'}<br />
                                    <strong>Cantidad:</strong> {value.clicks || 'N/A'}
                                  </Card.Text>
                                )
                              ))}
                            </CardGroup>
                            <Card.Text>
                              <strong>Total Cost:</strong> {ticket.totalCost}
                            </Card.Text>
                            <Card.Text>
                              <strong>Status:</strong>
                              <span style={{ color: this.getStatusColor(ticket.status), fontSize: '0.48em' }}>
                                {ticket.status}
                              </span>
                            </Card.Text>
                            <Card.Footer>
                              <small className="text-muted" style={{ fontSize: '0.48em' }}>ID: {ticket.idTicket}</small>
                            </Card.Footer>
                          </Card.Body>
                        </Card>
                      </Col>
                    ));
                  })
                ) : (
                  <div>No hay tickets disponibles</div>
                )}
              </Row>
              <Offcanvas show={this.state.frameConfirmClose} onHide={this.handleCloseOffcanvas} placement="bottom">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Confirmar Cierre de Ticket</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <p>¿Estás seguro de que deseas cerrar este ticket?</p>
                  <Button variant="danger" onClick={this.handleConfirmCloseTicket}>
                    Cerrar Ticket
                  </Button>
                  <Button variant="secondary" onClick={this.handleCloseOffcanvas}>
                    Cancelar
                  </Button>
                </Offcanvas.Body>
              </Offcanvas>

              <Modal show={this.state.modalStatusTicket} onHide={this.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Cambiar Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formStatusSelect">
                    <Form.Label>Seleccione el nuevo status</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.pointStatusTicket}
                      onChange={this.handleChangeStatus}
                    >
                      <option value=""></option>
                      <option value="preparando">Preparando</option>
                      <option value="pagado">Pagado</option>
                      <option value="cancelado">Cancelado</option>
                      <option value="cerrar">Cerrar</option>
                    </Form.Control>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseModal}>
                    Cerrar
                  </Button>
                  <Button variant="primary" disabled={this.state.secureState} onClick={this.handleSaveStatus}>
                    Guardar Cambios
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div> : null
        }
      </>
    );
  }

}
