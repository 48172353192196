import React, { Component } from 'react'
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { LoadHomePage } from '../store/MetaStore';

export default class LoadHome extends Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = LoadHomePage.init;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.detectMobile);
        this.detectMobile();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.detectMobile);
    }

    detectMobile = () => {
        const isMobile = window.innerWidth <= 768; 
        this.setState({ isMobile });
    };

    handleMouseMove = (e) => {
        if (this.state.isMobile) return;
        const container = e.currentTarget;
        const { left, top, width, height } = container.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const mouseY = e.clientY - top;
        const moveX = (mouseX - width / 2) * -0.3;
        const moveY = (mouseY - height / 2) * -0.3;
        container.style.transform = `translate(${moveX}px, ${moveY}px)`;
    };

    handleMouseLeave = (e) => {
        if (this.state.isMobile) return;
        const container = e.currentTarget;
        container.style.transform = 'translate(0, 0)';
    };

    handleTouchMove = (e) => {
        if (!this.state.isMobile) return;
        const touch = e.touches[0];
        const container = this.containerRef.current;
        const { left, top, width, height } = container.getBoundingClientRect();
        const touchX = touch.clientX - left;
        const touchY = touch.clientY - top;
        const moveX = (touchX - width / 2) * -0.3; 
        const moveY = (touchY - height / 2) * -0.3;

        container.style.transform = `translate(${moveX}px, ${moveY}px)`;
    };

    handleScroll = () => {
        if (!this.state.isMobile) return;
        const container = this.containerRef.current;
        const scrollPosition = window.scrollY;
        const moveY = -(scrollPosition * 0.3); 
        container.style.transform = `translateY(${moveY}px)`;
    };


    render() {
        return (
            <header className='App-header'>
                <Card className='card-no-background no-border'>
                    <CardBody>
                        <Row className='item-justify-center'>
                            <Col md={4}>
                                <div>
                                    <h1 className='rounded-font'>Mi sistemita</h1>
                                    <p className='informative-text'>Administra tus redes en una sola app,
                                         de paso monetiza , todo en segundos...
                                    </p>
                                </div>
                            </Col>
                            
                                <Col className='hover-container' ref={this.containerRef}
                                    onMouseMove={this.handleMouseMove}
                                    onMouseLeave={this.handleMouseLeave}
                                    onTouchMove={this.handleTouchMove}
                                    md={4}>
                                    <img src={this.state.smartPhone} className='hover-image' width={'55%'} />
                                </Col>
                                                           
                        </Row>
                    </CardBody>
                </Card>
            </header >
        )
    }
}
