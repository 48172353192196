import React, { Component } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardTitle, FormControl, InputGroup } from 'react-bootstrap'
import { FaCircleChevronLeft, FaRegCalendar, FaRegCalendarCheck } from 'react-icons/fa6'
import { LoadProduction } from '../store/MetaStore'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import db from '../firebase'

export default class FramePays extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
    }

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }

    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = this.cleanPathname()
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }



    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }


    handleToggleEnableCardPay() {
        this.state.enableCardPay === false ? this.setState({ enableCardPay: true }) : this.setState({ enableCardPay: false })
    }

    async handleSetState() {
        if (this.state.userAccessToken.length !== 73 && this.state.correctPublicKey) {
            try {                
                await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);

                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Configuración emparejada con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'success',
                });
            } catch (e) {
                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Error al emparejar con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'danger',
                });
            }
        }
    }

    handleBackButtonClick = () => {
        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
        } else {
            console.error("onCrossBtn is not a function");
        }
    };

    render() {
        return (
            <div>
                <Card>

                    <CardBody className='t-center'>
                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                    </CardBody>

                    <CardBody>
                        <Card>
                            <CardBody>
                                <CardTitle>Habilitar pagos por transferencia</CardTitle>
                                <InputGroup>
                                    <FormControl
                                        placeholder='Ingresa tu cuenta CLABE'
                                        maxLength={18}
                                        onChange={(e) => { this.setState({ userCLABE: e.target.value }) }}
                                    />
                                </InputGroup>
                            </CardBody>
                            <CardBody>

                                <CardTitle>Habilitar pagos con tarjeta y mergadopago</CardTitle>
                                < div style={{ display: 'inline-flex', cursor: 'pointer' }}>

                                    {this.state.enableCardPay === true ? <FaRegCalendarCheck onClick={() => { this.handleToggleEnableCardPay() }} /> : <FaRegCalendar onClick={() => { this.handleToggleEnableCardPay() }} />}

                                </div>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.userPublicKey === '' ? 'Ingresa tu Public Key' : this.state.userPublicKey}
                                        maxLength={44}
                                        value={this.state.userPublicKey === '' ? 'Ingresa tu Public Key' : this.state.userPublicKey}
                                        onChange={(e) => {
                                            this.setState({ userPublicKey: e.target.value })
                                            if (e.target.value.length !== 43) {
                                                this.setState({ correctPublicKey: true });
                                            } else {
                                                this.setState({ correctPublicKey: false });
                                            }
                                        }}
                                    />
                                </InputGroup>
                                <br />
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.userAccessToken === '' ? 'Ingresa tu Access Token' : this.state.userAccessToken}
                                        maxLength={74}
                                        value={this.state.userAccessToken === '' ? 'Ingresa tu Access Token' : this.state.userAccessToken}
                                        onChange={(e) => {
                                            this.setState({ userAccessToken: e.target.value })
                                            if (e.target.value.length !== 73) {                                           
                                                this.setState({ correctInput: true },()=>{});
                                                
                                            } else {
                                                this.setState({ correctInput: false });
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </CardBody>
                            <CardFooter>
                                <Button onClick={() => { this.handleSetState() }}>Aplicar Cambios </Button>
                            </CardFooter>
                        </Card>
                    </CardBody>
                </Card>
                {this.state.userAccessToken.length !== 74 && (
                    <Alert variant="danger" className="mt-2 p-absolute">
                        El Access Token debe tener exactamente 74 caracteres.
                        y la public Key 44, revisa antes de guardar
                    </Alert>
                )}
            </div>
        )
    }
}
