const estadosMunicipios = {
    "Aguascalientes": [
        "Aguascalientes",
        "Asientos",
        "Calvillo",
        "Jesús María",
        "Rincón de Romos",
        "San José de Gracia",
        "Tepezalá",
        "El Llano",
        "Pabellón de Arteaga",
        "Cosío"
    ],
    "Baja California": [
        "Ensenada",
        "Mexicali",
        "Tecate",
        "Tijuana",
        "Playas de Rosarito"
    ],
    "Baja California Sur": [
        "Comondú",
        "La Paz",
        "Loreto",
        "Los Cabos",
        "Mulegé"
    ],
    "Campeche": [
        "Calkiní",
        "Campeche",
        "Carmen",
        "Champotón",
        "Hecelchakán",
        "Hopelchén",
        "Palizada",
        "Tenabo",
        "Escárcega",
        "Calakmul",
        "Candelaria"
    ],
    "Colima": [
        "Armería",
        "Colima",
        "Comala",
        "Coquimatlán",
        "Cuauhtémoc",
        "Ixtlahuacán",
        "Manzanillo",
        "Minatitlán",
        "Tecomán",
        "Villa de Álvarez"
    ],
    "Coahuila": [
        "Abasolo",
        "Acuña",
        "Allende",
        "Arteaga",
        "Candela",
        "Castaños",
        "Cuatrociénegas",
        "Escobedo",
        "Francisco I. Madero",
        "Frontera",
        "General Cepeda",
        "Guerrero",
        "Hidalgo",
        "Jiménez",
        "Juárez",
        "Lamadrid",
        "Matamoros",
        "Monclova",
        "Morelos",
        "Múzquiz",
        "Nadadores",
        "Nava",
        "Ocampo",
        "Parras",
        "Piedras Negras",
        "Progreso",
        "Ramos Arizpe",
        "Sabinas",
        "Sacramento",
        "Saltillo",
        "San Buenaventura",
        "San Juan de Sabinas",
        "San Pedro",
        "Sierra Mojada",
        "Torreón",
        "Viesca",
        "Villa Unión",
        "Zaragoza"
    ],
    "Nayarit": [
        "Acaponeta",
        "Ahuacatlán",
        "Amatlán de Cañas",
        "Compostela",
        "Huajicori",
        "Ixtlán del Río",
        "Jala",
        "La Yesca",
        "Rosamorada",
        "Ruíz",
        "San Blas",
        "San Pedro Lagunillas",
        "Santa María del Oro",
        "Santiago Ixcuintla",
        "Tecuala",
        "Tepic",
        "Tuxpan",
        "Xalisco",
        "Del Nayar",
        "Bahía de Banderas"
    ],
    "Nuevo León": [
        "Abasolo",
        "Agualeguas",
        "Los Aldamas",
        "Allende",
        "Anáhuac",
        "Apodaca",
        "Aramberri",
        "Bustamante",
        "Cadereyta Jiménez",
        "El Carmen",
        "Cerralvo",
        "China",
        "Doctor Arroyo",
        "Doctor Coss",
        "Doctor González",
        "Galeana",
        "García",
        "San Pedro Garza García",
        "General Bravo",
        "General Escobedo",
        "General Terán",
        "General Treviño",
        "Guadalupe",
        "Los Herreras",
        "Higueras",
        "Hualahuises",
        "Iturbide",
        "Juárez",
        "Lampazos de Naranjo",
        "Linares",
        "Marín",
        "Melchor Ocampo",
        "Mier y Noriega",
        "Mina",
        "Montemorelos",
        "Monterrey",
        "Paras",
        "Pesquería",
        "Los Ramones",
        "Rayones",
        "Sabinas Hidalgo",
        "Salinas Victoria",
        "San Nicolás de los Garza",
        "San Rafael",
        "Santa Catarina",
        "Santiago",
        "Vallecillo",
        "Villaldama"
    ],
    "Jalisco": [
        "Acatic",
        "Acatlán de Juárez",
        "Ahualulco de Mercado",
        "Amacueca",
        "Amatitán",
        "Ameca",
        "Arandas",
        "Atemajac de Brizuela",
        "Atengo",
        "Atenguillo",
        "Atotonilco el Alto",
        "Atoyac",
        "Autlán de Navarro",
        "Ayotlán",
        "Ayutla",
        "Bolaños",
        "Cabo Corrientes",
        "Casimiro Castillo",
        "Cihuatlán",
        "Cocula",
        "Colotlán",
        "Concepción de Buenos Aires",
        "Cuautitlán de García Barragán",
        "Cuautla",
        "Cuquío",
        "Chapala",
        "Chimaltitán",
        "Degollado",
        "Ejutla",
        "El Arenal",
        "El Grullo",
        "El Limón",
        "Encarnación de Díaz",
        "Etzatlán",
        "Gómez Farías",
        "Guachinango",
        "Guadalajara",
        "Hostotipaquillo",
        "Huejúcar",
        "Huejuquilla el Alto",
        "Ixtlahuacán de los Membrillos",
        "Ixtlahuacán del Río",
        "Jalostotitlán",
        "Jamay",
        "Jesús María",
        "Jilotlán de los Dolores",
        "Jocotepec",
        "Juanacatlán",
        "Juchitlán",
        "La Barca",
        "La Huerta",
        "Lagos de Moreno",
        "Magdalena",
        "Mascota",
        "Mazamitla",
        "Mexticacán",
        "Mezquitic",
        "Mixtlán",
        "Ocotlán",
        "Ojuelos de Jalisco",
        "Pihuamo",
        "Poncitlán",
        "Puerto Vallarta",
        "Quitupan",
        "San Cristóbal de la Barranca",
        "San Diego de Alejandría",
        "San Gabriel",
        "San Ignacio Cerro Gordo",
        "San Juan de los Lagos",
        "San Juanito de Escobedo",
        "San Julián",
        "San Marcos",
        "San Martín de Bolaños",
        "San Martín Hidalgo",
        "San Miguel el Alto",
        "Gómez Farías",
        "Sayula",
        "Tala",
        "Talpa de Allende",
        "Tamazula de Gordiano",
        "Tapalpa",
        "Tecalitlán",
        "Tecolotlán",
        "Tenamaxtlán",
        "Teocaltiche",
        "Teocuitatlán de Corona",
        "Tepatitlán de Morelos",
        "Tequila",
        "Teuchitlán",
        "Tizapán el Alto",
        "Tlajomulco de Zúñiga",
        "Tlaquepaque",
        "Tolimán",
        "Tomatlán",
        "Tonala",
        "Tonaya",
        "Tonila",
        "Totatiche",
        "Tototlán",
        "Tuxcacuesco",
        "Tuxcueca",
        "Tuxpan",
        "Unión de San Antonio",
        "Unión de Tula",
        "Valle de Guadalupe",
        "Valle de Juárez",
        "Villa Corona",
        "Villa Guerrero",
        "Villa Hidalgo",
        "Villa Purificación",
        "Yahualica de González Gallo",
        "Zacoalco de Torres",
        "Zapopan",
        "Zapotiltic",
        "Zapotitlán de Vadillo",
        "Zapotlán del Rey",
        "Zapotlán el Grande",
        "Zapotlanejo"
    ],
    "Estado de México": [
        "Acambay",
        "Acolman",
        "Aculco",
        "Almoloya de Alquisiras",
        "Almoloya de Juárez",
        "Almoloya del Río",
        "Amanalco",
        "Amatepec",
        "Amecameca",
        "Apaxco",
        "Atenco",
        "Atizapán",
        "Atizapán de Zaragoza",
        "Atlacomulco",
        "Atlautla",
        "Axapusco",
        "Ayapango",
        "Calimaya",
        "Capulhuac",
        "Chalco",
        "Chapa de Mota",
        "Chapultepec",
        "Chiautla",
        "Chicoloapan",
        "Chiconcuac",
        "Chimalhuacán",
        "Coacalco de Berriozábal",
        "Coatepec Harinas",
        "Cocotitlán",
        "Coyotepec",
        "Cuautitlán",
        "Cuautitlán Izcalli",
        "Donato Guerra",
        "Ecatepec de Morelos",
        "Ecatzingo",
        "El Oro",
        "Huehuetoca",
        "Hueypoxtla",
        "Huixquilucan",
        "Isidro Fabela",
        "Ixtapaluca",
        "Ixtapan de la Sal",
        "Ixtapan del Oro",
        "Jaltenco",
        "Jilotepec",
        "Jilotzingo",
        "Jiquipilco",
        "Jocotitlán",
        "Joquicingo",
        "Juchitepec",
        "La Paz",
        "Lerma",
        "Luvianos",
        "Malinalco",
        "Melchor Ocampo",
        "Metepec",
        "Mexicaltzingo",
        "Morelos",
        "Naucalpan de Juárez",
        "Nextlalpan",
        "Nicolás Romero",
        "Nopaltepec",
        "Ocoyoacac",
        "Ocuilan",
        "Otumba",
        "Otzoloapan",
        "Otzolotepec",
        "Ozumba",
        "Papalotla",
        "Polotitlán",
        "Rayón",
        "San Antonio la Isla",
        "San Felipe del Progreso",
        "San José del Rincón",
        "San Martín de las Pirámides",
        "San Mateo Atenco",
        "San Simón de Guerrero",
        "Santo Tomás",
        "Soyaniquilpan de Juárez",
        "Sultepec",
        "Tecámac",
        "Tejupilco",
        "Temamatla",
        "Temascalapa",
        "Temascalcingo",
        "Temascaltepec",
        "Temoaya",
        "Tenancingo",
        "Tenango del Aire",
        "Tenango del Valle",
        "Teoloyucan",
        "Teotihuacán",
        "Tepetlaoxtoc",
        "Tepetlixpa",
        "Tepotzotlán",
        "Tequixquiac",
        "Texcaltitlán",
        "Texcalyacac",
        "Texcoco",
        "Tezoyuca",
        "Tianguistenco",
        "Timilpan",
        "Tlalmanalco",
        "Tlalnepantla de Baz",
        "Tlatlaya",
        "Toluca",
        "Tonanitla",
        "Tonatico",
        "Tultepec",
        "Tultitlán",
        "Valle de Bravo",
        "Valle de Chalco Solidaridad",
        "Villa de Allende",
        "Villa del Carbón",
        "Villa Guerrero",
        "Villa Victoria",
        "Xalatlaco",
        "Xonacatlán",
        "Zacazonapan",
        "Zacualpan",
        "Zinacantepec",
        "Zumpahuacán",
        "Zumpango"
    ],
    "Chihuahua": [
        "Ahumada",
        "Aldama",
        "Allende",
        "Aquiles Serdán",
        "Ascensión",
        "Bachíniva",
        "Balleza",
        "Batopilas",
        "Bocoyna",
        "Buenaventura",
        "Camargo",
        "Carichí",
        "Casas Grandes",
        "Chihuahua",
        "Chínipas",
        "Coronado",
        "Coyame del Sotol",
        "Cuauhtémoc",
        "Cusihuiriachi",
        "Delicias",
        "Dr. Belisario Domínguez",
        "Galeana",
        "Gran Morelos",
        "Guachochi",
        "Guadalupe",
        "Guadalupe y Calvo",
        "Guazapares",
        "Guerrero",
        "Hidalgo del Parral",
        "Huejotitán",
        "Ignacio Zaragoza",
        "Janos",
        "Jiménez",
        "Juárez",
        "Julimes",
        "La Cruz",
        "López",
        "Madera",
        "Maguarichi",
        "Manuel Benavides",
        "Matachí",
        "Matamoros",
        "Meoqui",
        "Morelos",
        "Moris",
        "Namiquipa",
        "Nonoava",
        "Nuevo Casas Grandes",
        "Ocampo",
        "Ojinaga",
        "Praxedis G. Guerrero",
        "Riva Palacio",
        "Rosales",
        "Rosario",
        "San Francisco de Borja",
        "San Francisco de Conchos",
        "San Francisco del Oro",
        "Santa Bárbara",
        "Satevó",
        "Saucillo",
        "Temósachic",
        "Urique",
        "Uruachi",
        "Valle de Zaragoza"
    ]
}

export default estadosMunicipios;
