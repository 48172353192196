import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore';
import db, { storage } from '../firebase';
import { collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { Card, CardGroup, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'react-bootstrap';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export default class FrameTicketSts extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
    }

    obtenerSegundoElemento(cadena) {
        const partes = cadena.split('/');
        return partes[2] || '';
    }

    obtenerTercerElemento(cadena) {
        const partes = cadena.split('/');
        return partes[3] || '';
    }


    obtenerCuartoElemento(cadena) {
        const partes = cadena.split('/');
        return partes[4] || '';
    }

    getStatusColor(status) {
        switch (status.toLowerCase()) {
            case 'pendiente a pago':
                return 'yellow';
            case 'cancelado':
                return 'red';
            case 'error':
                return 'red';
            case 'pagado':
                return 'green';
            case 'completado':
                return 'blue';
            case 'preparando':
                return 'orange';
            default:
                return 'black';
        }
    }


    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = pathname.startsWith('/tickets/') ? pathname.substring('/tickets/'.length) : pathname;
        const siem = this.obtenerSegundoElemento(pathname);
        const tird = this.obtenerTercerElemento(pathname);
        const fout = this.obtenerCuartoElemento(pathname)
        const license = await doc(db, '$:__site-tickets', tird)
        this.setState({ nameSite: fout })
        if (siem !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true,
                    pointUserTicket: isLicenseAvalible.data().pointMonitor.ticketsNow.find(ticket => ticket.idTicket === tird),
                }, async () => {
                    const idTicketsInTicketsNow = isLicenseAvalible.data().pointMonitor.ticketsNow.find(ticket => ticket.idTicket === tird)                   
                    if (idTicketsInTicketsNow.mptrue === true && fout === 'pagado' && idTicketsInTicketsNow.securePayOn === false) {          
                        const updatedTickets = isLicenseAvalible.data().pointMonitor.ticketsNow.map(ticket =>
                            ticket.idTicket === tird
                                ? { ...ticket, status: 'pagado' } 
                                : ticket
                        );
                        await updateDoc(license, {
                            'pointMonitor.ticketsNow': updatedTickets
                        });
                    } else {
                        const updatedTickets = isLicenseAvalible.data().pointMonitor.ticketsNow.map(ticket =>
                            ticket.idTicket === tird
                                ? { ...ticket, securePayOn: true } 
                                : ticket
                        );
                        await updateDoc(license, {
                            'pointMonitor.ticketsNow': updatedTickets
                        });
                    }
                })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }


    handleModalStatus(ticket) {
        this.clearUpdateInterval();
        this.setState({
            modalStatusTicket: true,
            selectedTicket: ticket,
            pointStatusTicket: ticket.status,
        });
    }

    handleCloseModal() {
        this.setState({
            modalStatusTicket: false,
            selectedTicket: null,
            pointStatusTicket: '',
            frameConfirmClose: false,
        }, () => {
            if (!this.state.modalStatusTicket) {
                this.startInterval();
            }
        });
    }

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }

    async getUrlTitle() {
        const loadResurses = () => {
            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/ticket-' + this.state.pointNameItem.idTicket + '.jpg'))
                    .then((url) => {
                        this.setState({
                            noImg: url,
                            sampleImg: url,
                        })
                    }).catch((error) => {
                
                    })
            } catch (e) {
           
            }
        }
        loadResurses()
    }

    handleTitleImg(c) {
        if (this.state.pointNameItem !== '') {
            let fileData = c.target.files[0]
            const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/revibo.jpg');
            uploadBytes(storageRef, fileData).then((snapshot) => {
                this.getUrlTitle()
            })
        }
    }

    render() {
        const { data = {}, idTicket, nameId, status, totalCost } = this.state.pointUserTicket || {};

        return (
            <div className='App'>
                <div className='App-header p-5'>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>{nameId}</Card.Title>
                                    <CardGroup className="ticket-card">
                                        {Object.entries(data).map(([key, value]) => (
                                            Array.isArray(value) ? (
                                                <table key={key} style={{ fontSize: '0.8em', width: '100%', marginBottom: '1em' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Para</th>
                                                            <th>Cantidad</th>
                                                            <th>Precio</th>
                                                            <th>Detalles</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {value.map((item, index) => (
                                                            item.keyItems.map((subItem, subIndex) => (
                                                                <tr key={`${key}-${index}-${subIndex}`}>
                                                                    <td>{item.name || 'N/A'}</td>
                                                                    <td>{subItem.clicks || 'N/A'}</td>
                                                                    <td>{subItem.priceItem || 'N/A'}</td>
                                                                    <td>{subItem.keyItem || 'N/A'}</td>
                                                                </tr>
                                                            ))
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <Card.Text key={key} style={{ fontSize: '0.8em' }}>
                                                    <strong>{key}:</strong> {value.name || 'N/A'}<br />
                                                    <strong>Cantidad:</strong> {value.clicks || 'N/A'}
                                                </Card.Text>
                                            )
                                        ))}
                                    </CardGroup>
                                    <Card.Text>
                                        <strong>Total:</strong> ${totalCost}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Status:</strong>
                                        <span style={{ color: this.getStatusColor(status), fontSize: '0.9em' }}> {status} </span>
                                    </Card.Text>
                                    <Card.Footer>
                                        <small className="text-muted" style={{ fontSize: '0.8em' }}>ID: {idTicket}</small>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal>
                    <ModalHeader closeButton />
                    <ModalBody>
                    <p>Cargar comprobante</p>
                                <img src={this.state.noImg} width={'33%'} onClick={() => { this.crossBtnItemStore() }} />
                                <Form.Control
                                    style={{ display: 'none' }}
                                    id='itemImg'
                                    type='file'
                                    
                                    accept='image/png,image/jpeg'
                                    onChange={(e) => this.handleTitleImg(e)}
                                />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}