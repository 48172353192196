import React, { Component } from 'react';
import { FaEraser, FaPaintBrush } from 'react-icons/fa'; // Iconos de react-icons
import { SketchPicker } from 'react-color'; // Para el selector de color
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { LoadProduction } from '../../store/MetaStore';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import db from '../../firebase';

class FXmaker extends Component {
  constructor(props) {
    super(props);
    this.state = LoadProduction.init
    this.canvasRef = React.createRef();
    this.imageInputRef = React.createRef();
    this.isDrawing = false;
  }

  async componentDidMount() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    this.ctx = ctx;
    canvas.addEventListener('mousedown', this.handleMouseDown);
    canvas.addEventListener('mousemove', this.handleMouseMove);
    canvas.addEventListener('mouseup', this.handleMouseUp);
    canvas.addEventListener('mouseleave', this.handleMouseUp);
    canvas.addEventListener('touchstart', this.handleTouchStart);
    canvas.addEventListener('touchmove', this.handleTouchMove);
    canvas.addEventListener('touchend', this.handleTouchEnd);
    canvas.addEventListener('touchcancel', this.handleTouchEnd);
    const { pathname } = window.location;
    const cleanedPathname = pathname.startsWith('/admin/') ? pathname.substring('/admin/'.length) : pathname;
    const license = await doc(db, '$:__sites-name', cleanedPathname)
    this.setState({ nameSite: cleanedPathname })
    if (cleanedPathname !== '') {
      const isLicenseAvailable = await getDoc(license)
      // this.state = isLicenseAvailable.data()
      if (isLicenseAvailable.exists()) {
        const data = isLicenseAvailable.data();
        this.setState(
          {
            ...data,
            pageExist: true
          },
          () => {
            if (data.frameFXmkr) {
              this.loadSavedSprites(data.frameFXmkr);
            }
          }
        );
      } else {
        setTimeout(() => {
          alert('Este sitio no existe');
          window.location = '/';
        }, 1500);
      }
    }
  }

   loadSavedSprites = async (savedSprites) => {
    const updatedSprites = savedSprites.map((spriteData) => {
      if (spriteData.data && spriteData.data.length === spriteData.width * spriteData.height * 4) { // Asegura que el tamaño sea correcto para ImageData
        return new ImageData(
          new Uint8ClampedArray(spriteData.data),
          spriteData.width,
          spriteData.height
        );
      }
      return null;
    });
  
    this.setState({ sprites: updatedSprites }, () => { 
      const { currentFrame } = this.state;
      if (updatedSprites[currentFrame]) {
        this.ctx.putImageData(updatedSprites[currentFrame], 0, 0);
      }
    });
    await this.handleSaveSprite();
  };

  handleMouseDown = (e) => {
    this.isDrawing = true;
    this.draw(e);
  };

  handleMouseMove = (e) => {
    if (!this.isDrawing) return;
    this.draw(e);
  };

  handleMouseUp = () => {
    this.isDrawing = false;
  };

  handleTouchStart = (e) => {
    e.preventDefault(); 
    this.isDrawing = true;
    this.drawTouch(e);
  };

  handleTouchMove = (e) => {
    if (!this.isDrawing) return;
    this.drawTouch(e);
  };

  handleTouchEnd = () => {
    this.isDrawing = false;
  };

  handleFrameSelection = async (index) => {
    const { sprites } = this.state;
    this.setState({ currentFrame: index });
    const ctx = this.ctx;
    ctx.clearRect(0, 0, 64, 64);
    if (sprites[index]) {
      ctx.putImageData(sprites[index], 0, 0);
    }

  };

  draw = (e) => {
    const ctx = this.ctx;
    const rect = this.canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (this.state.isErasing) {
      ctx.clearRect(x, y, 1, 1);
    } else {
      ctx.fillStyle = this.state.drawingColor; 
      ctx.fillRect(x, y, this.state.strokeSize, this.state.strokeSize)
    }
    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64);
    this.setState({ sprites: updatedSprites });
  };


  drawTouch = (e) => {
    const ctx = this.ctx;
    const rect = this.canvasRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    if (this.state.isErasing) {
      ctx.clearRect(x, y, 1, 1); 
    } else {
      ctx.fillStyle = this.state.drawingColor; 
      ctx.fillRect(x, y, 1, 1);
    }

    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64);
    this.setState({ sprites: updatedSprites });
  }


  handleSaveSprite = async () => {
    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = this.ctx.getImageData(0, 0, 64, 64);

    this.setState({ sprites: updatedSprites }, async () => {
      alert(`Sprite guardado en el frame ${currentFrame}.`);

           const savedSprites = updatedSprites.map((sprite) => ({
        data: sprite ? Array.from(sprite.data) : [],
        width: sprite ? sprite.width : 64,
        height: sprite ? sprite.height : 64,
      }));
      await updateDoc(doc(db, '$:__sites-name', this.state.nameSite), { frameFXmkr: savedSprites });

      if (this.props.updateFXState) {
        this.props.updateFXState(this.state);
      }
    });
  };

  handleLoadImage = () => {
    const file = this.imageInputRef.current.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const ctx = this.ctx;
        ctx.clearRect(0, 0, 64, 64); 
        ctx.drawImage(img, 0, 0, 64, 64); 

        const { currentFrame, sprites } = this.state;
        const updatedSprites = [...sprites];
        updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64);
        this.setState({ sprites: updatedSprites }, () => {
          alert(`Imagen cargada en el frame ${currentFrame}.`);
        });
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }


  handleAnimation = () => {
    this.setState({ isAnimating: !this.state.isAnimating }, this.animate);
  };
  
  animate = () => {
    if (!this.state.isAnimating) return;
    const { sprites, currentFrame } = this.state;
    const nextFrame = (currentFrame + 1) % 5; 
    const ctx = this.ctx;
    ctx.clearRect(0, 0, 64, 64); 
    if (sprites[nextFrame]) {
      ctx.putImageData(sprites[nextFrame], 0, 0);
    }
    this.setState({ currentFrame: nextFrame });
    setTimeout(this.animate, 300);
  };

  toggleEraser = () => {
    this.setState({ isErasing: !this.state.isErasing });
  };

  handleColorChange = (color) => {
    this.setState({ drawingColor: color.hex });
  };

  increaseStrokeSize = () => {
    this.setState((prevState) => ({ strokeSize: prevState.strokeSize + 1 }));
  };

  decreaseStrokeSize = () => {
    this.setState((prevState) => ({
      strokeSize: Math.max(prevState.strokeSize - 1, 1), 
    }));
  };

  render() {
    const { isAnimating, sprites, currentFrame, isErasing, drawingColor } = this.state;
    return (
      <div>
        <h3>Editor FX</h3>
        <Row>
          <Col>
            <canvas
              ref={this.canvasRef}
              width={64}
              height={64}
              style={{ border: '1px solid black' }}
            />
          </Col>
          <Col>
            <div>
              <Card>
                <CardBody>
                  <button onClick={this.toggleEraser}>
                    {isErasing ? (
                      <>
                        <FaEraser /> Borrador Activado
                      </>
                    ) : (
                      <>
                        <FaPaintBrush /> Borrador Desactivado
                      </>
                    )}
                  </button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button onClick={this.decreaseStrokeSize}>-</button>
                  <span>Tamaño de trazo: {this.state.strokeSize}</span>
                  <button onClick={this.increaseStrokeSize}>+</button>
                </CardBody>
              </Card>
            </div>
            <div>
              <SketchPicker
                width={100}
                color={drawingColor}
                onChange={this.handleColorChange}
                disableAlpha={true} 
              />
            </div>
          </Col>
        </Row>

        <input
          type="file"
          ref={this.imageInputRef}
          accept="image/*"
          onChange={this.handleLoadImage}
        />
        <br />
        <br />
        <button onClick={this.handleAnimation}>
          {isAnimating ? 'Detener Animación' : 'Iniciar Animación'}
        </button>
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          {sprites.map((sprite, index) => (
            <div
              key={index}
              onClick={() => this.handleFrameSelection(index)}
              style={{
                width: 64,
                height: 64,
                border: index === currentFrame ? '2px solid blue' : '1px solid black',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            >
              <canvas
                width={64}
                height={64}
                ref={(el) => {
                  if (el && sprite) {
                    const ctx = el.getContext('2d');
                    ctx.putImageData(sprite, 0, 0);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FXmaker;
