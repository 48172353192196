import React, { Component } from 'react'
import FrameAccess from './FrameAccess'

export default class LoadAccess extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <>
                <div className='App-header'>

                    <FrameAccess />
                </div>
            </>
        )
    }
}
