
import React from 'react';


const LoadCreateCodeStaple = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            className="logo-animation"
            style={{ width: '100%', maxWidth: '300px' }}
        >
            {/* Background Circle */}
            <circle
                cx="250"
                cy="250"
                r="200"
                fill="#FFD700"
                className="pulse-circle"
            />

            {/* Inner Sun Details */}
            <circle cx="250" cy="250" r="120" fill="#FF8C00" />

            {/* Rotating Rays */}
            {[...Array(12)].map((_, i) => (
                <rect
                    key={i}
                    x="245"
                    y="20"
                    width="10"
                    height="60"
                    fill="#FFD700"
                    transform={`rotate(${i * 30}, 250, 250)`}
                    className="rotating-rays"
                />
            ))}

            {/* Text */}
            <text
                x="250"
                y="270"
                textAnchor="middle"
                fontSize="32"
                fontWeight="bold"
                fill="#FFFFFF"
                style={{ fontFamily: 'Arial, sans-serif' }}
            >
                Mi Sistemita
            </text>
        </svg>
    );
};

export default LoadCreateCodeStaple;

/* CSS (LogoAnimation.css) */
