import SmartPhone from '../Assets/smart.png'
import NoImg from '../Assets/noimg.png'
import Desktop from '../Assets/display.png'
import Pixel from '../Assets/pixelnocolor.png'
import estadosMunicipios from '../Component/Common/EstadosMunicipios';

const createSCID = (mainOff) => {
    let selectMainInfo = mainOff
    let seedTok = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    let makeToken = ""
    seedTok.map(dates => {
        let min = 0
        let max = 9
        let rand = (Math.random() * (max - min)) + min
        makeToken += seedTok[rand.toFixed(0)]
    })
    let seedKey = ['a', 'b', 'c', 'd', 'e', 'f', 'g', '0', '1', '2', '3', '4', '5', '6', '7', 'D', 'A', 'B', 'T']
    let makeKey = ""
    seedKey.map(dates => {
        let min = 0
        let max = 18
        let rand = (Math.random() * (max - min)) + min
        makeKey += seedKey[rand.toFixed(0)]
        return makeKey
    })
    return { makeKey }
}

export const LoadIcons = {
    init: {
        displayMain: false,
        mainDisplay: 'display-block',
        pathUser: '',
    }
}

export const LoadApp = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        
        loading: true, error: null,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        preferenceId:'',
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadApps = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadCreateApp = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        currentInputItems: [],
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadTicketCreation = {
    init: {
        pageExist:false,
        pointMonitor: {
            ticketsNow: {
                status: 'pendiente a pago',
                data: [],
                nameId: '',
                idTicket: '',
                mptrue: false ,
                totalCost: '',
            },
        },
        createdDate: new Date().toLocaleString(),
        siteName: '',


    }
}


export const LoadProduction = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        AccessMap: [
            { a: 'Standar', b: 'Duracion de 1 año', c: 'Pagos mediante MercadoPago', d: 'Notificaciones', e: 'DDSN', f: '$5,722.45' },
            { a: 'Plus', b: 'Duracion de 5 años', c: 'Pagos mediante MercadoPago', d: 'Notificaciones', e: 'DDSN', f: '$17,493.31' },
            { a: 'Staff', b: 'Soporte de 5 años', c: 'Inlcuye todas las carracteristicas de mi sistemita', d: 'Acceso al codigo', e: 'DDSN', f: '$274,089.62' },
        ],
        buyOn: '',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: {
            fontSyle:'normal',        
            backgroundImage:`linear-gradient(to bottom, #2d85d7, #0a0a0f)`
        },
        choiseColorBackground: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `linear-gradient(to bottom, #2d85d7, #0a0a0f)`
        },
        choiseStyleMain: 'c-items',
        cardBackground: {      
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `linear-gradient(to bottom, #2d85d7, #0a0a0f)`
        },
        configFrameStpZ: false,
        displayCharts: 'none',
        drawingColor: 'black',
        desktop: Desktop,
        drawingColor: '#000',
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        defaultImages: {
            full: Pixel,
            sky: Pixel,
            center: Pixel,
            base: Pixel,
        },
        enableMainFX: false,

        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        enableTransfer: false,
        enableCardPay: false,
        frameFXmkr: [],
        frameOn: 'frame-left-sample',
        frameConfirmClose: false,
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        isAnimating: false,
        isErasing: false,
        isValidUrl: false,
        isValidMail:false,
        isValidMap:false,
        isValidWhats:false,
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        messages: [
            { Nombre: 'Maszov', comentario: '¡Genial!', estrellas: '5', artículo: 'Enchiladas' },
            { Nombre: 'Massin', comentario: 'Me encanta', estrellas: '4', artículo: 'Pollo' },
          ],
        modalCreateTicket: false,
        modalAlert: false,
        modalSetOffCanvas: false,
        modalItemsCard: false,
        modalStatusTicket: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pointIdTracker: '',
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointItemLog: [],
        pointImgMapBackFull:'',
        pointImgMapBackSky:'',
        pointImgMapBackBase:'',
        pointImgMapBackCenter:'',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointLogoImgBase64: '',
        pointGradientDirection: 'top',
        pointColorAlpha: '#7fc6d3',
        pointColorBetha: '#e6dea3',
        pointBtnGradientDirection: 'top',
        pointBtnColorAlpha: '#7fc6d3',
        pointBtnColorBetha: '#e6dea3',
        pointBackgroundColorAlpha: '#7fc6d3',
        pointBackgroundColorBetha: '#e6dea3',
        pointBackgroundGradientDirection:'top',
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        pointStatusTicket: NoImg,
        pointVoucherUser:NoImg,
        pointFaceUrl: 'https://facebook.com/...',
        pointMapsUrl: 'https://www.google.com/maps...',
        pointSeedWhatsUrl:'https://api.whatsapp.com/send?phone=',
        pointWhatsUrl:'+52550051000',
        roleCreate: false,
        rowStyleMld: {
            height: '284px',
            width: '330px',
            overflow: 'auto',
            flexWrap: 'wrap',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        pointUserTicket: {
            data: "Sin datos disponibles",
            idTicket: "BaB463f42371D6dBd27",
            nameId: "Identificador o traquer",
            status: "pendiente a pago",
            totalCost: 0
        },
        rndId:'',
        userCLABE: '',
        userPublicKey: '',
        userAccessToken: '',
        userTypeApp:'Alimentos',
        sampleImg: NoImg,
        srcImgMapBackFull:'',
        srcImgMapBackSky:'',
        srcImgMapBackBase:'',
        srcImgMapBackCenter:'',
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureState: false,
        selectTicket: null,
        securePayOn:false,
        secureUser: 'display-none',
        setFavicon: '',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        tags: [],
        transitioning: false,
        timestamp: '',
        totalToPay:'0',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,        
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserCommit:'',
        txtUserStart:'',
        txtUserPass: '',
        txtUserName:'',
        txtUserMail:'',
        txtUserRegionA: Object.keys(estadosMunicipios),
        txtUserRegionB: [],
        txtUserZoneA: '',
        txtUserZoneB: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        currentFrame: 0,
        starCount: 100,
        sprites: Array(5).fill(null),
        strokeSize: 1,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadHomePage = {

    init: {
        smartPhone: SmartPhone,
        isMobile: false,
        keyLicense: '',
        desktop: Desktop,
    }

}