import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore'
import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../firebase';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, FormControl, InputGroup } from 'react-bootstrap'

export default class StartPage extends Component {

    constructor(props) {
        super(props)
        this.state = LoadProduction.init;
    }

    async nextStep() {
        this.props.nextFrame(false)
    }


    render() {
        return (
            <Card className='w-85'>
                <CardHeader>
                    <CardTitle>{this.state.txtTitle[0]}</CardTitle>
                </CardHeader>
                <CardBody>
                    <p>Con unos sensillos pasos podras crear tu app</p>
                </CardBody>
                <CardFooter>
                    <Button onClick={() => { this.nextStep() }}>{this.state.btnTxtTitle[1]}</Button>
                    <br />
                    <p className='f-s-1 c-pointer m-t-1' onClick={() => { window.location = '/' }}>Volver</p>
                </CardFooter>
            </Card>
        )
    }
}
