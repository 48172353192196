import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import LoadAppUser from './Component/LoadAppUser';
import Home from './Component/Home';
import LoadMain from './Component/LoadMain';
import Tutoriales from './Component/Tutoriales';
import LoadMonitorFrame from './Component/LoadMonitorFrame';
import FrameTicketSts from './Component/FrameTicketSts';
import LoadAccess from './Component/LoadAccess';
import LoadCreateCodeStaple from './Component/LoadCreateCodeStaple';

class RoutingApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteName: 'default', 
      pointIdTracker:'',
    };
  }

  async componentDidMount() { 
    const { pathname } = window.location;
    const siteName = pathname.replace('/', '');
    const pointIdTracker = pathname.replace('/','')
    const idState = pathname.replace('/','')

    this.setState({ siteName,pointIdTracker,idState});
  }

  render() {
    const { siteName,pointIdTracker,idState } = this.state;

    return (
     
        <BrowserRouter>
          <Routes>
            <Route path='/Creatuapp' element={<App />} />
            <Route path='/' element={<Home/> } />
            <Route path='/Accesos' element={ <LoadAccess />} />
            <Route path='/tutoriales' element={<Tutoriales />}/> 
            <Route path='/:siteName' element={<LoadAppUser siteName={siteName} />} />
            <Route path='/tickets/:siteName/:pointIdTracker/:idState' element={<FrameTicketSts siteName={siteName} pointIdTracker={pointIdTracker} idState={idState} />} />
            <Route path='/admin/:siteName' element={<LoadMain siteName={siteName} />} />
            <Route path='/monitor/:siteName' element={<LoadMonitorFrame siteName={siteName} />} />
            <Route path='/samplethe-code' element={<LoadCreateCodeStaple />} />
            
          </Routes>
        </BrowserRouter>
   
    );
  }
}

export default RoutingApp;