import React, { Component } from 'react'
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import db, { storage } from '../firebase';
import { LoadProduction } from '../store/MetaStore';
import { Button, Row, Col, FormControl, Card, CardBody, InputGroup, CardTitle, Form, Modal, ModalBody, Dropdown, CardFooter, Badge, Offcanvas, Collapse, ModalHeader, CardHeader, } from 'react-bootstrap';
import { CiGrid2H, CiGrid2V, CiGrid41, CiCirclePlus } from "react-icons/ci";
import { RiSecurePaymentLine } from "react-icons/ri";
import { FaCircleChevronRight, FaRegCalendar, FaRegCalendarCheck, FaCircleChevronLeft, FaXmark } from "react-icons/fa6";
import AlertMsn from './Common/AlertMsn';
import FXmaker from './Common/FXmaker';


export default class LoadAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init;
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleConsole = this.handleConsole.bind(this);
        this.handleModalItemsClose = this.handleModalItemsClose.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this)
        this.handleNameItem = this.handleNameItem.bind(this)
    }

    cleanPathname() {
        const { pathname } = window.location;
        const decodedPathname = decodeURIComponent(pathname);
        return decodedPathname.startsWith('/admin/')
            ? decodedPathname.substring('/admin/'.length)
            : decodedPathname;
    }


    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = this.cleanPathname()
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                }, () => { })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 2000);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }



    handleInputChange(e) {
        this.setState({
            txtTracker: e.target.value,
            config: e.target.value,
            newTag: e.target.value,
        })
    }

    handleNxtConfig() {
        this.setState({
            configFrameStpZ: true,
        })
    }

    handleBckConfig() {
        this.setState({
            configFrameStpZ: false,
        })
    }

    handleBackButtonClick = () => {
        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
        } else {
            console.error("onCrossBtn is not a function");
        }
    };

    handleToggleMultiCard() {
        this.state.enableMultiCard === 'display-block' ? this.setState({ enableMultiCard: 'display-none' }) : this.setState({ enableMultiCard: 'display-block' })
    }

    handleToggleSecure() {
        this.state.secureUser === 'display-block' ? this.setState({ secureUser: 'display-none' }) : this.setState({ secureUser: 'display-block' })
    }

    handleToggleFX() {
        this.state.enableFxFlame === 'display-block' ? this.setState({ enableFxFlame: 'display-none' }) : this.setState({ enableFxFlame: 'display-block' })
    }

    handleToggleFxTxt() {
        this.state.enableFxText === 'display-block' ? this.setState({ enableFxText: 'display-none' }) : this.setState({ enableFxText: 'display-block' })
    }

    handleModalItems(e) {
        if (e != undefined) {
            const totalTagValues = e.itemTags.reduce((acc, tag) => parseInt(acc) + parseInt((tag.value || 0)), 0);
            this.setState({
                enableFxFlame: e.enableFxFlame,
                enableFxText: e.enableFxText,
                noImg: e.src,
                modalItemsCard: true,
                pointNameItem: e.name,
                pointDetailsItem: e.attribute,
                pointItemSeudoCost: totalTagValues,
                pointItemTotalCost: e.priceItem,
                tags: e.itemTags,
                sprites: e.frameFXmkr || []
            });
        } else {
            this.setState({
                modalItemsCard: true,
            });
        }
    }

    handleModalItemsClose() {
        const { nameTempItem, descriptionTempItem, itemsMap, pointItemTotalCost } = this.state;
        this.setState({
            modalItemsCard: false,
            nameTempItem: '',
            descriptionTempItem: '',
            modalItemsCard: false,
            noImg: this.state.itemImgSrc,
            modalWelcome: true,
        }, () => {
            this.handleRemoveAllTags();
        })
    }

    async handleRemoveAllTags() {
        let totalItem = this.state.pointItemTotalCost;
        const updatedTags = [];
        this.setState({
            tags: updatedTags,
            pointItemSeudoCost: 0,
        });
    };

    handleWeave(ch, newName) {
        this.setState((prevState) => {

            const itemIndex = prevState.itemsList.findIndex((item) => item.name === ch);
            if (itemIndex !== -1) {
                const updatedItemsList = [...prevState.itemsList];
                updatedItemsList[itemIndex] = {
                    ...updatedItemsList[itemIndex],
                    ch: newName,
                };
                return { itemsList: updatedItemsList };
            }

            return {
                itemsList: [...prevState.itemsList, { id: new Date().getTime(), ch: newName }],
            };
        });
    }

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }

    async getUrlTitle() {
        const loadResurses = () => {
            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/' + this.state.pointNameItem + '.jpg'))
                    .then((url) => {
                        this.setState({
                            noImg: url,
                            sampleImg: url,
                        })
                    }).catch((error) => {

                    })
            } catch (e) {

            }
        }
        loadResurses()
    }

    handleTitleImg(c) {
        if (this.state.pointNameItem !== '') {
            let fileData = c.target.files[0]
            const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/' + this.state.sscid + '/' + this.state.pointNameItem + '.jpg');
            uploadBytes(storageRef, fileData).then((snapshot) => {
                this.getUrlTitle()
            })
        }
    }

    handleRemoveTag = (tagToRemove) => {

        let a = tagToRemove.value
        let b = this.state.pointItemSeudoCost

        let totalItem = parseInt(b) - parseInt(a)

        this.setState((prevState) => ({
            tags: prevState.tags.filter(tag => tag !== tagToRemove),
            pointItemSeudoCost: totalItem,
        }));

    };

    toggleCheck = (checkId) => {
        this.setState((prevState) => ({
            selectedCheck: prevState.selectedCheck === checkId ? null : checkId
        }));
    }

    checkTimeWindow() {
        const currentHour = new Date().getHours();
        const allowedStartHour = 9;
        const allowedEndHour = 17;

        return currentHour >= allowedStartHour && currentHour < allowedEndHour;
    }

    async handleNewItem() {
        const { pointNameItem, pointDetailsItem, itemsMap, pointItemTotalCost } = this.state;
        const newItem = {
            name: pointNameItem,
            attribute: pointDetailsItem,
            src: this.state.noImg,
            itemTags: this.state.tags,
            itemsList: true,
            priceItem: pointItemTotalCost,
            enableFxFlame: this.state.enableFxFlame,
            enableFxText: this.state.enableFxText,
            txtFxMsn: this.state.txtFxMsn,
            clicks: 0,
            showCounter: false,
        };

        this.setState({
            itemsMap: { ...itemsMap, [pointNameItem]: newItem },
            pointNameItem: 'Nombre del Item',
            pointDetailsItem: 'Descripcion del Item',
            modalItemsCard: false,
            noImg: this.state.itemImgSrc,
            modalWelcome: true,
            itemsList: true,
        }, async () => {
            await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);
            this.handleRemoveAllTags();
        })
    }



    handleTestBtn() {

    }

    calculateTotal() {
        const { tags } = this.state;
        let a = this.state.pointItemSeudoCost
        let b = this.state.pointItemSubcost
        let totalItem = parseInt(a) + parseInt(b)
        this.setState({ pointItemSeudoCost: totalItem })
        return tags.reduce((sum, tag) => sum + tag.value, 0);
    };

    handleDeleteItemFromMap = async () => {
        try {
            const { siteName, pointNameItem } = this.state;
            const itemDelete = doc(db, '$:__sites-name', siteName);
            const selectMap = await getDoc(itemDelete);
            if (selectMap.exists()) {
                const currentItemMap = selectMap.data().itemsMap;
                const updatedItemMap = { ...currentItemMap };
                delete updatedItemMap[pointNameItem];
                const hasMoreItems = Object.keys(updatedItemMap).length > 0;

                this.setState({
                    itemsMap: updatedItemMap,
                    modalSetOffCanvas: false,
                    modalWelcome: !hasMoreItems,
                }, () => {
                    this.handleCloseAlert();
                });


            } else {
                console.error("Documento no encontrado");
            }
        } catch (error) {
            console.error("Error al eliminar el item del mapa: ", error);
        }
    };


    handleAddTag() {
        const { newTag, pointItemSubName, pointItemSubcost, tags } = this.state;
        this.setState({
            newTag: this.state.pointItemSubName,
        })
        if (newTag.trim() && !tags.includes(newTag) && !tags.some(tag => tag.name === newTag)) {
            this.setState({
                tags: [...tags, { name: pointItemSubName, value: pointItemSubcost }],
                pointItemSubcost: '',
                pointItemSubName: '',
            });
        }
        this.calculateTotal()
        document.getElementById('new-tag').value = ''
        document.getElementById('new-value').value = ''

    };

    async handleUpdateCong() {
        try {

            this.setState({
                modalAlert: true,
                txtMainAlert: 'Configuracion emparejada con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'success',
            }, async () => {
                this.setState({
                    modalAlert: false,
                    menuToActive: 'none'
                })
                await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state)
            })
        } catch (e) {
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Error al emparejar con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'danger',
            })
        }
    }


    handleToggleModal(c) {
        const { itemsMap } = this.state;

        if (!itemsMap || Object.keys(itemsMap).length === 0) {
            this.setState({
                modalAlert: true,
                txtMainAlert: 'No hay artículos disponibles en la tienda para activar esta opción.',
                txtTitleAlert: 'Advertencia',
                alertState: 'warning',
            });
            return;
        }
        this.setState((prevState) => {
            if (!prevState.modalWelcome) {
                return {
                    modalWelcome: true,
                    modalAlert: false,
                };
            } else {

                return {
                    modalWelcome: false,
                    modalAlert: true,
                    txtMainAlert: `Has Activado el sitio web ir a misistemita.app/${prevState.nameSite} y validar, no olvides guardar los cambios`,
                    txtTitleAlert: 'Activación / Desactivación de sitio',
                    alertState: 'success',
                };
            }
        })
    }

    updateFXState = (newState) => {
        this.setState({ fxMakerState: newState });
    }

    async clearCache() {
        this.handleModalItemsClose()
        const hasMoreItems = Object.keys(this.state.itemsMap).length > 0;
        this.setState({
            modalWelcome: hasMoreItems,
            modalAlert: false,
            modalItemsCard: false,
            modalSetOffCanvas: false,
            noImg: this.state.itemImgSrc,


        }, async () => await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state),)
    }

    handleConsole() {
        this.setState({
            utaStep: true
        })
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false, modalSetOffCanvas: false });
        this.clearCache()
    };


    handleTongleFX() {
        this.setState({ enableMainFX: !this.state.enableMainFX });
    }

    handleNameItem(a) {
        this.setState({ pointNameItem: a })
    }

    handleUpdate() {
        const {
            pointBtnGradientDirection,
            pointBtnColorAlpha,
            pointBtnColorBetha,
            pointBackgroundGradientDirection,
            pointBackgroundColorAlpha,
            pointBackgroundColorBetha,
        } = this.state;

        this.setState({
            choiseBtnColor: {
                fontStyle: "normal",
                backgroundImage: `linear-gradient(to ${pointBtnGradientDirection}, ${pointBtnColorAlpha}, ${pointBtnColorBetha})`,
            },
            cardBackground: {
                backgroundImage: `linear-gradient(to ${pointBackgroundGradientDirection}, ${pointBackgroundColorAlpha}, ${pointBackgroundColorBetha})`,
            },
        });
    }

    handleDirectionChange = (e) => {
        this.setState(
            { pointBtnGradientDirection: e.target.value },
            this.handleUpdate
        );
    };

    handleColor1Change = (e) => {
        this.setState(
            { pointBtnColorAlpha: e.target.value },
            this.handleUpdate
        );
    };

    handleColor2Change = (e) => {
        this.setState(
            { pointBtnColorBetha: e.target.value },
            this.handleUpdate
        );
    };

    handleDirectionChangeB = (e) => {
        this.setState(
            { pointBackgroundGradientDirection: e.target.value },
            this.handleUpdate
        );
    };

    handleColor1ChangeB = (e) => {
        this.setState(
            { pointBackgroundColorAlpha: e.target.value },
            this.handleUpdate
        );
    };

    handleColor2ChangeB = (e) => {
        this.setState(
            { pointBackgroundColorBetha: e.target.value },
            this.handleUpdate
        );
    };

    render() {
        const {
            cardBackground,
            pointBtnGradientDirection,
            pointBtnColorAlpha,
            pointBtnColorBetha,choiseBtnColor,tags,isOverLimit
        } = this.state;

        return (
            <>
                <div style={this.state.choiseColorBackground} className={'App'}>
                    <header className='App-header'>
                        <AlertMsn
                            modalAlert={this.state.modalAlert}
                            alertState={this.state.alertState}
                            txtTitleAlert={this.state.txtTitleAlert}
                            txtMainAlert={this.state.txtMainAlert}
                            handleCloseAlert={this.handleCloseAlert}
                        />
                        <Row className='t-center'>
                            <Col>
                                <Card>
                                    <CardBody>
                                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row className='w-90 '>
                            <Col>
                                <Card style={this.state.cardBackground} className={" m-50"} >
                                    <CardBody>
                                        <InputGroup>
                                            <FormControl
                                                placeholder={this.state.txtTraker}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                    <CardBody className={this.state.enableMultiCard}>
                                        <div className="check-container">
                                            <Form.Check
                                                type="checkbox"
                                                label="Individual"
                                                checked={this.state.selectedCheck === 1}
                                                onChange={() => this.toggleCheck(1)}
                                            />
                                        </div>
                                        <div className="check-container">
                                            <Form.Check
                                                type="checkbox"
                                                label="Separado"
                                                checked={this.state.selectedCheck === 2}
                                                onChange={() => this.toggleCheck(2)}
                                            />
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <Row style={this.state.rowStyleMld}>
                                            {this.state.itemsList === true ? <Col xs={this.state.size_col} key={"daas1"}>
                                                <div id='erD' onClick={() => { this.handleModalItems() }} className={'glass-effect c-pointer text-center'} key={"dsfae"}>
                                                    <CiCirclePlus className='position-absolute w-85 h-95 insert-area-center' />
                                                    <img className='img-card-no' src={this.state.noImg} alt='no-img.png' width={'100%'} />
                                                </div></Col>
                                                : <></>}

                                            {this.state.itemsList === true ? <>{
                                                Object.entries(this.state.itemsMap).map(([key, e], k) => (
                                                    <Col xs={this.state.size_col} key={k}>
                                                        <div onClick={() => { this.handleModalItems(e) }} className="glass-effect c-pointer text-center" key={k}>

                                                            <img className='img-card-no' src={e.src} alt='no-img.png' width={'100%'} />
                                                            <div className={`${e.enableFxFlame || ''} flame-container`} >
                                                                <svg className="flame" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill="#FF5722" d="M32,4C32,4,2,24,8,46c3.889,14.11,24,18,24,18s20.111-3.89,24-18C62,24,32,4,32,4z" />
                                                                    <path fill="#FF7043" d="M32,14c0,0-14.4,10.6-9.6,26.4C25.6,46.4,32,48,32,48s6.4-1.6,9.6-7.6C46.4,24.6,32,14,32,14z" />
                                                                    <path fill="#FF8A65" d="M32,26c0,0-5.3,3.9-3.2,10.8C30.1,40.3,32,41,32,41s1.9-0.7,3.2-4.2C37.3,29.9,32,26,32,26z" />
                                                                </svg>
                                                            </div>
                                                            <div className={`${e.enableFxText || ''} text-container`} >
                                                                <span className="neon-text">{e.txtFxMsn}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                            }</>
                                                :
                                                <Col xs={this.state.size_col} key={"da3s1"}>
                                                    <div id='er' onClick={() => { this.handleModalItems() }} className="glass-effect c-pointer text-center" key={"dsfae"}>
                                                        <CiCirclePlus className='position-absolute w-85 h-95 insert-area-center' />
                                                        <img className='img-card-no' src={this.state.sampleImg} alt='no-img.png' width={'100%'} />
                                                        <div className={`${this.state.enableFxFlame} flame-container`} >
                                                            <svg className="flame" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill="#FF5722" d="M32,4C32,4,2,24,8,46c3.889,14.11,24,18,24,18s20.111-3.89,24-18C62,24,32,4,32,4z" />
                                                                <path fill="#FF7043" d="M32,14c0,0-14.4,10.6-9.6,26.4C25.6,46.4,32,48,32,48s6.4-1.6,9.6-7.6C46.4,24.6,32,14,32,14z" />
                                                                <path fill="#FF8A65" d="M32,26c0,0-5.3,3.9-3.2,10.8C30.1,40.3,32,41,32,41s1.9-0.7,3.2-4.2C37.3,29.9,32,26,32,26z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </CardBody>
                                    <CardBody className={this.state.secureUser}>
                                        <InputGroup>
                                            <FormControl
                                                placeholder='Numero de contacto ,etc'
                                                onChange={(e) => this.setState({ btnToEnlist: e.target.value })}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                    <CardBody>
                                        <Button style={choiseBtnColor}>{this.state.btnToEnlist}</Button>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col>
                                {this.state.configFrameStpZ === false ? <>
                                    <Card style={cardBackground}>
                                        <CardBody>
                                            < FaCircleChevronRight className='position-absolute z-10' onClick={() => { this.handleNxtConfig() }} />
                                        </CardBody>
                                        <CardBody>
                                            <InputGroup>
                                                <FormControl
                                                    placeholder='Cambiar el texto de Pedido'
                                                    onChange={(e) => this.setState({ btnToEnlist: e.target.value })}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                        {/*/color de boton/*/}
                                        <CardTitle><h6>Cambiar Color de boton</h6></CardTitle>
                                        <CardBody>
                                            <div>
                                                <label>
                                                    Dirección de luz:
                                                    <select value={pointBtnGradientDirection} onChange={this.handleDirectionChange}>
                                                        <option value="top">Cielo</option>
                                                        <option value="bottom">Suelo</option>
                                                        <option value="left">Izq</option>
                                                        <option value="right">Der</option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    Color 1:
                                                    <input
                                                        type="color"
                                                        value={pointBtnColorAlpha}
                                                        onChange={this.handleColor1Change}
                                                    />
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    Color 2:
                                                    <input
                                                        type="color"
                                                        value={pointBtnColorBetha}
                                                        onChange={this.handleColor2Change}
                                                    />
                                                </label>
                                            </div>
                                        </CardBody>
                                        {/*/segimiento/*/}
                                        <CardBody>
                                            <InputGroup>
                                                <FormControl
                                                    placeholder='Cambiar Texto de seguimiento'
                                                    onChange={(e) => { this.setState({ txtTraker: e.target.value }) }}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                        {/*/tipo de menu/*/}
                                        <CardTitle><h6>Cambiar tipo de menu</h6></CardTitle>
                                        <CardBody>
                                            < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                                <div onClick={() => {
                                                    this.setState({
                                                        size_col: 12, rowStyleMld: {
                                                            height: '284px',
                                                            width: '330px',
                                                            overflow: 'auto',
                                                            flexWrap: 'nowrap',
                                                            alignItems: 'center',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        }
                                                    })
                                                }} ><CiGrid2V /></div>
                                                <div onClick={() => { this.setState({ size_col: 6 }) }} ><CiGrid41 /></div>
                                                <div onClick={() => {
                                                    this.setState({
                                                        size_col: 12, rowStyleMld: {
                                                            height: '284px',
                                                            width: '330px',
                                                            overflow: 'auto',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'center',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        }
                                                    })
                                                }} ><CiGrid2H /></div>
                                            </div>
                                        </CardBody>
                                    </Card> </> : <>
                                    {/*/Segundo menu/*/}
                                    <Card style={cardBackground}>
                                        <CardBody>
                                            <FaCircleChevronLeft className='position-absolute z-10' onClick={() => { this.handleBckConfig() }} />
                                        </CardBody>
                                        {/*/Activar multipedido/*/}
                                        <CardBody>
                                            <CardTitle><h6>Habilitar MultiPedido</h6></CardTitle>
                                            < div style={{ display: 'inline-flex', cursor: 'pointer' }}>

                                                {this.state.enableMultiCard === 'display-none' ? <FaRegCalendar onClick={() => { this.handleToggleMultiCard() }} /> : <FaRegCalendarCheck onClick={() => { this.handleToggleMultiCard() }} />}

                                            </div>
                                        </CardBody>
                                        {/*/Cambiar fondo /*/}
                                        <CardTitle><h6>Cambiar Color de Fondo</h6></CardTitle>
                                        <CardBody>
                                            <div>
                                                <label>
                                                    Dirección de luz:
                                                    <select value={this.state.pointBackgroundGradientDirection} onChange={this.handleDirectionChangeB}>
                                                        <option value="top">Cielo</option>
                                                        <option value="bottom">Suelo</option>
                                                        <option value="left">Izq</option>
                                                        <option value="right">Der</option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    Color 1:
                                                    <input
                                                        type="color"
                                                        value={this.state.pointBackgroundColorAlpha}
                                                        onChange={this.handleColor1ChangeB}
                                                    />
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    Color 2:
                                                    <input
                                                        type="color"
                                                        value={this.state.pointBackgroundColorBetha}
                                                        onChange={this.handleColor2ChangeB}
                                                    />
                                                </label>
                                            </div>
                                        </CardBody>
                                        <CardTitle><h6>Habilitar validacion de datos</h6></CardTitle>
                                        <CardBody>
                                            < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                                <div onClick={() => { this.handleToggleSecure() }} ><RiSecurePaymentLine /></div>
                                            </div>
                                        </CardBody>
                                        <CardTitle><h6>Habilidar sitio web</h6></CardTitle>
                                        <CardBody>
                                            < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                                <div >
                                                    {this.state.modalWelcome === true ? <FaRegCalendar onClick={() => { this.handleToggleModal('a') }} /> : <FaRegCalendarCheck onClick={() => { this.handleToggleModal('b') }} />}
                                                    <span style={{ fontSize: '0.59em' }}>Mensaje a mostrar</span>
                                                    <br />
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder={this.state.txtModalWelcome}
                                                            type='text'
                                                            maxLength={128}
                                                            onChange={(e) => { this.setState({ txtModalWelcome: e.target.value }) }}
                                                        />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card></>}
                                {/*/Segundo menu/*/}
                                <Card style={cardBackground}>
                                    <CardBody className='text-center'>
                                        <CardTitle>
                                            Guardar cambios
                                        </CardTitle>
                                        <Button onClick={() => { this.handleUpdateCong() }}>Aceptar</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </header>
                </div >

                <Modal className='transparent no-background' show={this.state.modalItemsCard} onHide={this.handleModalItemsClose} >

                    <Modal.Body className='transparent no-background no-border'>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleModalItemsClose() }} />
                                    </Col>
                                    <Col>
                                        <FaXmark className=' r-items w-100' onClick={() => { this.setState({ modalSetOffCanvas: true }) }} />
                                    </Col>
                                </Row></CardHeader>
                            <CardBody>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.pointNameItem}
                                        onChange={(e) => {
                                            this.handleNameItem(e.target.value)
                                        }}
                                    />
                                </InputGroup>
                            </CardBody>
                            <CardBody>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.pointDetailsItem}
                                        onChange={(e) => {
                                            this.setState({
                                                pointDetailsItem: e.target.value
                                            })
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <FormControl
                                        placeholder={this.state.pointItemTotalCost >= 0 ? this.state.pointItemTotalCost : 'Valor total del Item'}
                                        type='number'
                                        value={this.state.pointItemTotalCost >= 0 ? this.state.pointItemTotalCost : 'Valor total del Item'}
                                        onChange={(e) => {
                                            this.setState({
                                                pointItemTotalCost: e.target.value
                                            })
                                        }}
                                    />
                                </InputGroup>
                            </CardBody>
                            {//------------------------------------------------------------------------
                            }
                            <CardBody className='t-center c-pointer'>
                                <p>Imagen de item</p>
                                <img src={this.state.noImg} width={'33%'} onClick={() => { this.crossBtnItemStore() }} />
                                <Form.Control
                                    style={{ display: 'none' }}
                                    id='itemImg'
                                    type='file'

                                    accept='image/png,image/jpeg'
                                    onChange={(e) => this.handleTitleImg(e)}
                                />
                            </CardBody>
                            <CardTitle>Activar efectos</CardTitle>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <p>Fuego</p>
                                        {this.state.enableFxFlame === 'display-none' ? <FaRegCalendar onClick={() => { this.handleToggleFX() }} /> : <FaRegCalendarCheck onClick={() => { this.handleToggleFX() }} />}
                                    </Col>
                                    <Col>
                                        <p>Mensaje</p>
                                        {this.state.enableFxText === 'display-none' ? <FaRegCalendar onClick={() => { this.handleToggleFxTxt() }} /> : <FaRegCalendarCheck onClick={() => { this.handleToggleFxTxt() }} />}
                                        <InputGroup>
                                            <FormControl
                                                placeholder='Escribe un mensaje 12 caractectes max'
                                                maxLength={12}
                                                onChange={(e) => { this.setState({ txtFxMsn: e.target.value }) }}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col className='display-none'>
                                        <Button className='display-none'
                                            onClick={() => this.handleTongleFX()}
                                        >
                                            Menu de Efectos
                                        </Button>
                                        <Collapse in={this.state.enableMainFX}>
                                            <div >

                                            </div>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </CardBody>
                            {//------------------------------------------------------------------------
                            }
                            <CardBody>
                                <InputGroup >
                                    <FormControl
                                        id='new-tag'
                                        placeholder="Nombre del condimento"
                                        name="newTag"
                                        onChange={(e) => this.setState({ pointItemSubName: e.target.value, newTag: e.target.value, })}
                                    />
                                    <FormControl
                                        id='new-value'
                                        placeholder="Valor del mismo"
                                        name="newValue"
                                        onChange={(e) => this.setState({ pointItemSubcost: e.target.value })}
                                    />
                                    <Button onClick={this.handleAddTag}>
                                        Agregar
                                    </Button>
                                </InputGroup>
                                <div className="mt-3">
                                    {tags.map((tag, index) => (
                                        <Badge key={index} pill bg="primary" className="me-2">

                                            {tag.name} : {tag.value}
                                            <Button variant="link" size="sm" onClick={() => this.handleRemoveTag(tag)}>X</Button>
                                        </Badge>
                                    ))}
                                    <Card>
                                        <CardBody>
                                            <span className='w-100 d-flex justify-content-between'>
                                                Total Costo: ${this.state.pointItemSeudoCost}
                                            </span>
                                            <span className={`badge ${isOverLimit ? 'bg-danger' : 'bg-success'}`}>
                                                {isOverLimit ? 'Gastos floreciendo: Te has sobrepasado en la receta' : 'Sano: Tus gastos/ingresos son ideales'}
                                            </span>
                                        </CardBody>
                                    </Card>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button onClick={() => { this.handleNewItem() }}>Agregar </Button>
                            </CardFooter>
                        </Card >
                    </Modal.Body >
                </Modal >
                <Offcanvas show={this.state.modalSetOffCanvas} onHide={this.handleCloseAlert} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Confirmar Eliminación</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p>¿Estás seguro que deseas eliminar el documento con id: {this.state.pointNameItem}?</p>
                        <Button variant="danger" onClick={this.handleDeleteItemFromMap}>
                            Sí, eliminar
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseAlert}>
                            Cancelar
                        </Button>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}
